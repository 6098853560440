import { Transition } from "@headlessui/react";
import clsx from "clsx";
import { createContext, useContext, useEffect, useRef, useState } from "react";

import GameUi from "~/components/game-ui/GameUi";
import { isMobile } from "~/utils";

type DeserializedChatMessage = {
  message: string;
  color?: string;
  playerId?: string;
};

type OnlinePlayer = {
  id: string;
  username: string;
  profilePictureUrl?: string;
};

const GameContext = createContext<{
  gameSlug: string;
  isGameOpen: boolean;
  isGameFocused: boolean;
  closeGame: () => void;
  sendMessage: (message: {
    type: string;
    message?: string;
    data?: Record<string, unknown>;
  }) => void;
  setGameUrl: (gameUrl: string) => void;
  openGame: (url: string, slug: string) => void;
  setIsGameFocused: (isGameFocused: boolean) => void;
  chatMessages: DeserializedChatMessage[];
  sendChatMessage: (message: string) => void;
  onlinePlayers: Map<string, OnlinePlayer>;
} | null>(null);

export function GameProvider({ children }: { children: React.ReactNode }) {
  const [gameUrl, setGameUrl] = useState("");
  const [gameSlug, setGameSlug] = useState("");
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [isGameFocused, setIsGameFocused] = useState(false);
  const [chatMessages, setChatMessages] = useState<DeserializedChatMessage[]>(
    [],
  );
  const [onlinePlayers, setOnlinePlayers] = useState<Map<string, OnlinePlayer>>(
    new Map(),
  );

  const openGame = (url: string, slug: string) => {
    setGameUrl(url);
    setGameSlug(slug);
    setIsGameFocused(true);
    setChatMessages([]);
    setOnlinePlayers(new Map());
  };

  const handleIframeLoad = () => {
    if (iframeRef.current && isMobile()) {
      iframeRef.current.requestFullscreen().catch((err) => {
        console.warn("Could not enter fullscreen mode:", err);
      });
    }
  };

  const closeGame = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen().catch((err) => {
        console.warn("Error exiting fullscreen:", err);
      });
    }

    setGameUrl("");
    setGameSlug("");
    setIsGameFocused(false);
    setChatMessages([]);
    setOnlinePlayers(new Map());
  };

  const sendMessage = (message: {
    type: string;
    message?: string;
    data?: Record<string, unknown>;
  }) => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow?.postMessage(message, "*");
    }
  };

  const sendChatMessage = (message: string) => {
    sendMessage({ type: "send-chat-message", message });
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Tab" && gameUrl && !isGameFocused) {
        setIsGameFocused(true);
        sendMessage({ type: "lock-pointer" });
        iframeRef.current?.focus();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [gameUrl, isGameFocused]);

  useEffect(() => {
    const handleMessage = (
      event: MessageEvent<{
        type: string;
        data:
          | {
              key: string;
              message?: string;
              id?: string;
              username?: string;
              profilePictureUrl?: string;
              removed?: boolean;
            }
          | DeserializedChatMessage;
      }>,
    ) => {
      const { type, data } = event.data;

      if (
        type === "key-down" &&
        "key" in data &&
        data.key === "Tab" &&
        isGameFocused &&
        gameUrl
      ) {
        setIsGameFocused(false);
        sendMessage({ type: "unlock-pointer" });
        iframeRef.current?.blur();
      }

      if (
        type === "chat-message" &&
        "message" in data &&
        typeof data.message === "string"
      ) {
        setChatMessages((prevMessages) => {
          const newMessages = [
            ...prevMessages,
            data as DeserializedChatMessage,
          ];
          return newMessages.slice(-100);
        });
      }

      if (
        type === "player-update" &&
        "id" in data &&
        typeof data.id === "string" &&
        data.id.length > 0
      ) {
        const playerId = data.id;
        setOnlinePlayers((prevPlayers) => {
          const newPlayers = new Map(prevPlayers);

          if (data.removed) {
            newPlayers.delete(playerId);
          } else if (data.username) {
            newPlayers.set(playerId, {
              id: playerId,
              username: data.username,
              profilePictureUrl: data.profilePictureUrl,
            });
          }

          return newPlayers;
        });
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, [gameUrl, isGameFocused]);

  useEffect(() => {
    if (gameUrl && isGameFocused) {
      document.documentElement.classList.add("!overflow-hidden");
      document.documentElement.classList.remove("overflow-y-scroll");
    } else {
      document.documentElement.classList.remove("!overflow-hidden");
      document.documentElement.classList.add("overflow-y-scroll");
    }
  }, [gameUrl, isGameFocused]);

  return (
    <GameContext.Provider
      value={{
        openGame,
        gameSlug,
        closeGame,
        setGameUrl,
        chatMessages,
        isGameFocused,
        sendChatMessage,
        sendMessage,
        setIsGameFocused,
        isGameOpen: Boolean(gameUrl),
        onlinePlayers,
      }}
    >
      <Transition
        as="div"
        show={!isGameFocused}
        enter="ease-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="relative z-10 transition-opacity"
      >
        {children}
      </Transition>

      <Transition
        as="div"
        show={Boolean(gameUrl)}
        enter="ease-out duration-[2s]"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={clsx("fixed inset-0 h-full w-full transition-opacity", {
          "z-50": isGameFocused,
          "pointer-events-none": !isGameFocused,
        })}
      >
        <GameUi />

        <iframe
          ref={iframeRef}
          width="100%"
          height="100%"
          src={gameUrl}
          allowFullScreen
          onLoad={handleIframeLoad}
          className={clsx("h-full w-full", {
            "pointer-events-none": !isGameFocused,
          })}
        />
      </Transition>
    </GameContext.Provider>
  );
}

export function useGame() {
  const context = useContext(GameContext);

  if (!context) {
    throw new Error("useGame must be used within a GameProvider");
  }

  return context;
}
