import clsx from "clsx";
import { type FormEvent, type RefObject, useEffect, useRef } from "react";

import { SendIcon } from "~/components/ui/icons/icon";
import { useGame } from "~/providers/GameContext";

type ChatMessagesProps = {
  isChatOpen: boolean;
  setIsChatOpen: (isOpen: boolean) => void;
  inputRef: RefObject<HTMLInputElement>;
};

export default function ChatMessages({
  isChatOpen,
  setIsChatOpen,
  inputRef,
}: ChatMessagesProps) {
  const chatMessagesRef = useRef<HTMLOListElement>(null);
  const { chatMessages, sendChatMessage, sendMessage, onlinePlayers } =
    useGame();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);
    const message = formData.get("message") as string;
    sendChatMessage(message);
    form.reset();
    setIsChatOpen(false);
    setTimeout(() => {
      sendMessage({ type: "lock-pointer" });
    }, 300);
  };

  useEffect(() => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  }, [chatMessages]);

  return (
    <div className="absolute left-6 top-24 w-[280px] lg:top-28 lg:w-[360px]">
      <div
        className={clsx(
          "overflow-hidden rounded-lg px-3 pb-3 transition lg:rounded-xl",
          {
            "bg-black/70": isChatOpen,
            "pt-3": chatMessages.length > 0,
          },
        )}
      >
        <ol
          ref={chatMessagesRef}
          className={clsx("flex max-h-60 flex-col gap-2", {
            "overflow-hidden": !isChatOpen,
            "pointer-events-auto overflow-y-auto": isChatOpen,
          })}
        >
          {chatMessages.map(({ message, color, playerId }, index) => (
            <li
              style={{ color }}
              key={`${playerId}-${index}`}
              className="pr-3 font-medium text-white/90 [text-shadow:-1px_-1px_0_rgba(0,0,0,0.3),1px_-1px_0_rgba(0,0,0,0.3),-1px_1px_0_rgba(0,0,0,0.3),1px_1px_0_rgba(0,0,0,0.3)] lg:text-lg/normal"
            >
              {playerId ? (
                <img
                  width="18"
                  height="18"
                  loading="lazy"
                  decoding="async"
                  alt="HYTOPIA Logo"
                  src="/img/logo-round.svg"
                  className="mr-2 inline-block rounded-full"
                />
              ) : null}

              {playerId && onlinePlayers.get(playerId) ? (
                <strong className="text-white">
                  {onlinePlayers.get(playerId)?.username}:{" "}
                </strong>
              ) : null}

              <span className="text-white/80">{message}</span>
            </li>
          ))}
        </ol>
        <form
          onSubmit={handleSubmit}
          className={clsx("relative -mx-3 -mb-3 transition duration-300", {
            "mt-3": chatMessages.length > 0,
            "pointer-events-auto": isChatOpen,
            "pointer-events-none opacity-0": !isChatOpen,
          })}
        >
          <img
            width="18"
            height="18"
            loading="lazy"
            decoding="async"
            alt="HYTOPIA Logo"
            src="/img/logo-round.svg"
            className="absolute left-3 top-3 rounded-full"
          />

          <input
            ref={inputRef}
            type="text"
            name="message"
            autoComplete="off"
            placeholder="Say something then hit enter..."
            className="w-full rounded-b-lg bg-black/90 px-12 py-3 text-white/90 outline-none duration-300 placeholder:text-white/60 lg:rounded-b-xl lg:text-lg/normal"
          />

          <button
            type="submit"
            className="absolute right-0 top-1 p-3 opacity-40 transition hover:opacity-100"
          >
            <SendIcon className="h-5 w-5" />
          </button>
        </form>
      </div>
    </div>
  );
}
