import { useEffect, useRef, useState } from "react";

import ChatButton from "~/components/game-ui/chat/ChatButton";
import ChatMessages from "~/components/game-ui/chat/ChatMessages";
import LogoButton from "~/components/game-ui/ui/LogoButton";
import { useGame } from "~/providers/GameContext";

type GameMessageEvent = MessageEvent<{
  type: string;
  data: { key: string; message?: string };
}>;

export default function GameUi() {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const { setIsGameFocused, isGameFocused, sendMessage } = useGame();

  useEffect(() => {
    if (isChatOpen) {
      inputRef.current?.focus();
    }
  }, [isChatOpen]);

  useEffect(() => {
    const handleKeyPress = (
      event: GameMessageEvent,
      key: string,
      prefix?: string,
    ) => {
      if (
        event.data.type === "key-down" &&
        event.data.data.key === key &&
        isGameFocused &&
        inputRef.current
      ) {
        inputRef.current.focus();
        if (prefix) {
          inputRef.current.value = `${prefix}${inputRef.current.value}`;
        }
        sendMessage({ type: "unlock-pointer" });
        setIsChatOpen(true);
      }
    };

    const handleMessage = (event: MessageEvent) => {
      const gameEvent = event as GameMessageEvent;
      handleKeyPress(gameEvent, "/", "/");
      handleKeyPress(gameEvent, "t");
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, [isGameFocused, sendMessage]);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape" && isGameFocused && inputRef.current) {
        inputRef.current.value = "";
        setIsChatOpen(false);
        setTimeout(() => {
          sendMessage({ type: "lock-pointer" });
        }, 300);
      }
    };

    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, [isGameFocused, sendMessage]);

  return (
    <div className="pointer-events-none absolute inset-0 z-50 h-full w-full">
      <div className="absolute left-6 top-6 flex gap-4">
        <LogoButton onClick={() => setIsGameFocused(false)} />
        <ChatButton onClick={() => setIsChatOpen(!isChatOpen)} />
      </div>

      <ChatMessages
        inputRef={inputRef}
        isChatOpen={isChatOpen}
        setIsChatOpen={setIsChatOpen}
      />
    </div>
  );
}
