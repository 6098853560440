import { MessageIcon } from "~/components/ui/icons/icon";

type ChatButtonProps = {
  onClick: () => void;
};

export default function ChatButton({ onClick }: ChatButtonProps) {
  return (
    <button
      type="button"
      onClick={onClick}
      className="pointer-events-auto w-[30px] rounded-lg bg-black/70 p-2 transition hover:bg-black/50 lg:w-[46px] lg:rounded-xl lg:p-3"
    >
      <MessageIcon
        width="24"
        height="24"
        className="mx-auto h-6 w-6 stroke-2 text-white lg:h-8 lg:w-8"
      />
    </button>
  );
}
