type LogoButtonProps = {
  onClick: () => void;
};

export default function LogoButton({ onClick }: LogoButtonProps) {
  return (
    <button
      type="button"
      onClick={onClick}
      className="pointer-events-auto w-[30px] rounded-lg bg-black/70 p-2 transition hover:bg-black/50 lg:w-[46px] lg:rounded-xl lg:p-3"
    >
      <img
        width="30"
        height="30"
        loading="lazy"
        decoding="async"
        alt="HYTOPIA Logo"
        src="/img/logo-square.svg"
        className="mx-auto h-7 w-7 lg:h-10 lg:w-10"
      />
    </button>
  );
}
